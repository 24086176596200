/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect, useRef, memo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAvailableUnits } from 'redux/modules/entities/selectors/products';
import isEqual from 'lodash/isEqual';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SelectionControl } from '@johnlewispartnership/wtr-ingredients/ingredients/Forms';
import { Chip } from '@johnlewispartnership/wtr-ingredients/ingredients/Chips';

import { KEY_ENTER } from 'constants/keys';
import { C62, weightOptions, GRM } from 'constants/weightOptions';

import { OPTION_TYPES } from 'ingredients/forms/input-types';
import { userAgent } from 'utils/user-agent';
import { setTimeout, clearTimeout } from 'utils/settimeout-wrapper';
import { getClickContext } from 'utils/clickContext';
import { formatAsPositivePoundsOrPence } from 'utils/currency';
import useSupercedingTimer from 'hooks/use-superceding-timer';
import useOnClickOutside from 'hooks/use-on-click-outside';
import { useOpenPreviousOrderNotesModal } from 'hooks';

import { LinkAsButton } from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';
import ProductPrice from 'components/Product/Price';

import Conflict from 'components/Conflict';
import ViewSimilar from 'components/ProductPod/ViewSimilar';
import { MESSAGE_TYPE as CONFLICT_MESSAGE_TYPE } from 'components/Conflict/Conflict';
import { conflictType } from 'components/Conflict/propTypes';

import useTrolleyActions from 'hooks/use-trolley-actions';
import { TROLLEY_TEXT } from './constants';

import styles from './index.scss';
import TrolleyButtons from './TrolleyButtons';
import { formatAmount } from './trolleyControlUtils';

const recentlyUpdatedTimeout = 2000;

const TrolleyControls = ({
  hideSimilar,
  isPersonalisableProduct,
  productUrl,
  productId,
  depositCharge,
  displayPriceEstimated,
  displayPriceQualifier,
  productName,
  orderId,
  isDisabled,
  setAdded,
  conflict,
  lineNumber,
  productPosition,
  searchType,
}) => {
  const inputRef = useRef();
  const plusButtonRef = useRef();
  const addButtonRef = useRef();
  const insideRef = useRef();
  const availableUnits = useSelector(state => getAvailableUnits(state, productId), isEqual);

  const {
    desiredAmount,
    desiredUom,
    selectDesiredUom,
    updateDesiredAmount,
    addToTrolley,
    updateInTrolley,
    isEditingTrolleyAmount,
    setEditingTrolleyAmount,
    optimisticTrolleyQuantityAmount,
    optimisticTrolleyQuantityUom,
  } = useTrolleyActions({ lineNumber, productId });

  const { updateTimeout: updateFocusTimeout } = useSupercedingTimer();

  const [isRecentlyUpdated, setRecentlyUpdated] = useState(false);
  const [recentlyUpdatedTimer, setRecentlyUpdatedTimer] = useState(null);
  const [btnAutoFocus, setBtnAutoFocus] = useState(false);
  const { shouldOpenModal: shouldOpenPreviousOrderNotesModal, openPreviousOrderNotesModal } =
    useOpenPreviousOrderNotesModal(searchType, orderId, lineNumber);

  const isProductInTrolley = !!optimisticTrolleyQuantityAmount;
  const weightUOMs = availableUnits ? availableUnits.filter(unit => unit !== C62) : [];
  const canBuyEach = availableUnits?.includes(C62);
  const defaultWeightUom =
    optimisticTrolleyQuantityUom !== C62 ? optimisticTrolleyQuantityUom : weightUOMs[0];
  const isWeightUom = desiredUom !== C62;
  const showInputField = isProductInTrolley || isWeightUom;
  const showUomSelector = !!weightUOMs.length && canBuyEach && !isDisabled;

  useEffect(() => {
    if (
      desiredAmount === optimisticTrolleyQuantityAmount &&
      desiredUom === optimisticTrolleyQuantityUom &&
      isEditingTrolleyAmount
    ) {
      if (isRecentlyUpdated) {
        const timer = setTimeout(() => {
          setRecentlyUpdated(false);
          setEditingTrolleyAmount(false);
        }, recentlyUpdatedTimeout);
        setRecentlyUpdatedTimer(timer);
      }
    }
  }, [
    desiredAmount,
    desiredUom,
    isRecentlyUpdated,
    isEditingTrolleyAmount,
    setRecentlyUpdated,
    setEditingTrolleyAmount,
    setRecentlyUpdatedTimer,
    optimisticTrolleyQuantityAmount,
    optimisticTrolleyQuantityUom,
  ]);

  const handleAddToTrolley = useCallback(
    e => {
      if (shouldOpenPreviousOrderNotesModal) {
        openPreviousOrderNotesModal(productPosition, getClickContext(e), desiredAmount, desiredUom);

        return;
      }

      addToTrolley({
        quantity: {
          amount: desiredAmount,
          uom: desiredUom,
        },
        clickContext: getClickContext(e),
        productPosition,
        searchType,
      });

      setAdded(true);
      setEditingTrolleyAmount(false);
      updateFocusTimeout(() => {
        plusButtonRef.current?.focus();
      }, 150);
    },
    [
      addToTrolley,
      desiredAmount,
      desiredUom,
      openPreviousOrderNotesModal,
      productPosition,
      searchType,
      setAdded,
      setEditingTrolleyAmount,
      shouldOpenPreviousOrderNotesModal,
      updateFocusTimeout,
    ],
  );

  const incrementInTrolley = useCallback(
    e => {
      updateInTrolley({
        newQuantity: {
          amount:
            optimisticTrolleyQuantityAmount + weightOptions[optimisticTrolleyQuantityUom].increment,
          uom: optimisticTrolleyQuantityUom,
        },
        clickContext: getClickContext(e),
        productPosition,
        searchType,
      });
    },
    [
      updateInTrolley,
      optimisticTrolleyQuantityAmount,
      optimisticTrolleyQuantityUom,
      productPosition,
      searchType,
    ],
  );

  const decrementInTrolley = useCallback(() => {
    const newAmount = (
      optimisticTrolleyQuantityAmount - weightOptions[optimisticTrolleyQuantityUom].increment
    ).toFixed(1);
    setBtnAutoFocus(true);
    updateInTrolley({
      newQuantity: {
        amount: newAmount > 0 ? newAmount : 0,
        uom: optimisticTrolleyQuantityUom,
      },
      productPosition,
      searchType,
    });
  }, [
    optimisticTrolleyQuantityAmount,
    optimisticTrolleyQuantityUom,
    productPosition,
    searchType,
    updateInTrolley,
  ]);

  const handleUpdateInTrolley = useCallback(
    e => {
      updateInTrolley({
        newQuantity: {
          amount: desiredAmount || 0,
          uom: desiredUom,
        },
        clickContext: getClickContext(e),
      });
      if (desiredAmount) {
        setRecentlyUpdated(true);
      }
      if (desiredAmount === '' || desiredAmount === 0) {
        setEditingTrolleyAmount(false);
      }
    },
    [updateInTrolley, desiredAmount, desiredUom, setEditingTrolleyAmount],
  );

  const removeFromTrolley = useCallback(
    e =>
      updateInTrolley({
        newQuantity: {
          amount: 0,
          uom: optimisticTrolleyQuantityUom,
        },
        clickContext: getClickContext(e),
      }),
    [optimisticTrolleyQuantityUom, updateInTrolley],
  );

  const handleUpdateKeyPress = useCallback(event => {
    if (event.charCode === KEY_ENTER || event.keyCode === KEY_ENTER) {
      setBtnAutoFocus(true);
    }
  }, []);

  const handleInputKeyDown = useCallback(
    event => {
      if (event.charCode === KEY_ENTER || event.keyCode === KEY_ENTER) {
        if (isProductInTrolley) {
          handleUpdateInTrolley(event);
          if (desiredAmount === 0 || desiredAmount === '') {
            setBtnAutoFocus(true);
          }
        } else {
          handleAddToTrolley(event);
          setBtnAutoFocus(true);
        }
      }
    },
    [handleAddToTrolley, desiredAmount, isProductInTrolley, handleUpdateInTrolley],
  );

  const selectUom = useCallback(
    (uom, checked) => {
      const ua = userAgent();

      const isAppleTouch =
        ua.os.family === 'iOS' ||
        ua.os.family === 'iPad OS' ||
        (ua.os.family === 'OS X' && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);

      const focusInput = !isAppleTouch && !isProductInTrolley && uom !== C62;

      if (checked) {
        if (recentlyUpdatedTimer) {
          clearTimeout(recentlyUpdatedTimer);
          setRecentlyUpdatedTimer(null);
          setEditingTrolleyAmount(false);
          setRecentlyUpdated(false);
        }
        selectDesiredUom(uom);
        if (isProductInTrolley && uom !== desiredUom) {
          setEditingTrolleyAmount(true);
        }
        if (focusInput) {
          updateFocusTimeout(() => {
            inputRef.current?.focus();
          }, 100);
        }
      }
    },
    [
      isProductInTrolley,
      recentlyUpdatedTimer,
      selectDesiredUom,
      desiredUom,
      setEditingTrolleyAmount,
      updateFocusTimeout,
    ],
  );

  const selectAmount = useCallback(
    event => {
      const {
        target: { value },
      } = event;
      if (recentlyUpdatedTimer) {
        clearTimeout(recentlyUpdatedTimer);
        setRecentlyUpdatedTimer(null);
        setEditingTrolleyAmount(false);
        setRecentlyUpdated(false);
      }

      const newAmount = formatAmount(desiredUom, value);

      if (newAmount <= weightOptions[desiredUom].maximum) {
        updateDesiredAmount(newAmount);
      }

      if (newAmount === 0) {
        setAdded(false);
      }

      if (newAmount > 0 && newAmount === optimisticTrolleyQuantityAmount) {
        setEditingTrolleyAmount(false);
      } else {
        setEditingTrolleyAmount(true);
      }
    },
    [
      recentlyUpdatedTimer,
      desiredUom,
      optimisticTrolleyQuantityAmount,
      setEditingTrolleyAmount,
      updateDesiredAmount,
      setAdded,
    ],
  );

  const handleFocus = () => inputRef.current.select();

  useEffect(() => {
    if (btnAutoFocus && !isProductInTrolley) {
      setBtnAutoFocus(false);
      updateFocusTimeout(() => {
        addButtonRef.current?.focus();
      }, 100);
    } else if (btnAutoFocus && isRecentlyUpdated) {
      setBtnAutoFocus(false);
      updateFocusTimeout(() => {
        plusButtonRef.current?.focus();
      }, recentlyUpdatedTimeout + 150);
    }
  }, [desiredAmount, isProductInTrolley, btnAutoFocus, isRecentlyUpdated, updateFocusTimeout]);

  useOnClickOutside(insideRef, () => {
    setEditingTrolleyAmount(false);
  });

  return (
    <div ref={insideRef} className={styles.trolleyControls} data-testid="trolleyControls">
      {showUomSelector && (
        <form className={styles.uom}>
          <SelectionControl
            name={`Add to trolley by item - ${lineNumber}`}
            type={OPTION_TYPES.RADIO}
            value={C62}
            checked={desiredUom === C62}
            fontWeight="lighter"
            onChange={selectUom}
            data-testid="itemUomSelector"
          >
            item
          </SelectionControl>
          <SelectionControl
            name={`Add to trolley by weight - ${lineNumber}`}
            type={OPTION_TYPES.RADIO}
            value={defaultWeightUom}
            checked={desiredUom === defaultWeightUom}
            fontWeight="lighter"
            onChange={selectUom}
            data-testid="weightUomSelector"
          >
            weight
          </SelectionControl>
        </form>
      )}
      <div className={classNames(styles.prices)}>
        <span data-test="product-pod-price" className={styles.itemPrice}>
          <p className="sr-only">{TROLLEY_TEXT.itemPrice}</p>
          <span>
            <ProductPrice productId={productId} />
            {displayPriceEstimated && (
              <span className={styles.priceEst}>
                {` ${TROLLEY_TEXT.each}`}
                <abbr title="estimated">{` ${TROLLEY_TEXT.estimated}`}</abbr>
              </span>
            )}
          </span>
        </span>
        {displayPriceQualifier ? (
          <span className={styles.pricePerUnit}>
            <p className="sr-only">{TROLLEY_TEXT.pricePer}</p>
            {/* removing parenthesis temporary */}
            {displayPriceQualifier.split(/[()]/).join('')}
          </span>
        ) : null}
      </div>
      {depositCharge && (
        <div className={styles.chipWrapper}>
          <Chip productChip small>
            {`+${formatAsPositivePoundsOrPence(depositCharge.amount)} deposit`}
          </Chip>
        </div>
      )}
      {!hideSimilar && !conflict && (
        <div className={styles.similarWrapper}>
          <ViewSimilar productId={productId} />
        </div>
      )}
      {isDisabled ? (
        <div className={styles.conflictWrapper}>
          <Conflict
            removeFromTrolley={removeFromTrolley}
            isInTrolley={optimisticTrolleyQuantityAmount > 0}
            entityId={productId}
            entityType="products"
            messageType={CONFLICT_MESSAGE_TYPE.short}
            textOnly
            position={productPosition}
          />
        </div>
      ) : isPersonalisableProduct ? (
        <LinkAsButton
          className={styles.viewProductButton}
          component={Link}
          data-testid={TROLLEY_TEXT.viewLink}
          to={productUrl}
          label={TROLLEY_TEXT.viewLink}
          theme="secondary"
        />
      ) : (
        <div className={classNames(styles.form, { [styles.withUomSelector]: showUomSelector })}>
          {/* eslint-disable jsx-a11y/label-has-associated-control */}
          <label
            data-testid="inputProductAmountWrapper"
            className={classNames({
              [styles.inputWrapper]: true,
              [styles.showUom]: desiredUom === defaultWeightUom,
              [styles.hide]: !showInputField,
            })}
          >
            {/* eslint-enable jsx-a11y/label-has-associated-control */}
            <input
              aria-label={`${weightOptions[desiredUom].ariaLabel} to add to trolley`}
              ref={inputRef}
              className={styles.input}
              data-testid="inputProductAmount"
              type="text"
              inputMode={desiredUom === GRM ? 'numeric' : 'decimal'}
              max={weightOptions[desiredUom].maximum}
              min={weightOptions[desiredUom].minimum}
              value={desiredAmount}
              onKeyDown={handleInputKeyDown}
              onChange={selectAmount}
              onFocus={handleFocus}
            />
            <div className={styles.inputUom}>{weightOptions[desiredUom].label}</div>
          </label>

          <TrolleyButtons
            {...{
              isRecentlyUpdated,
              isProductInTrolley,
              isEditingTrolleyAmount,
              isDisabled,
              handleUpdateKeyPress,
              updateInTrolley: handleUpdateInTrolley,
              decrementInTrolley,
              incrementInTrolley,
              addToTrolley: handleAddToTrolley,
              addButtonRef,
              plusButtonRef,
              productName,
            }}
          />
        </div>
      )}
    </div>
  );
};

TrolleyControls.propTypes = {
  hideSimilar: PropTypes.bool,
  isPersonalisableProduct: PropTypes.bool,
  productUrl: PropTypes.string,
  productId: PropTypes.string.isRequired,
  depositCharge: PropTypes.shape({
    amount: PropTypes.number.isRequired,
  }),
  displayPriceEstimated: PropTypes.bool.isRequired,
  displayPriceQualifier: PropTypes.string,
  isDisabled: PropTypes.bool,
  productName: PropTypes.string.isRequired,
  setAdded: PropTypes.func.isRequired,
  conflict: conflictType,
  lineNumber: PropTypes.string.isRequired,
  productPosition: PropTypes.number,
  searchType: PropTypes.string,
  orderId: PropTypes.string,
};

TrolleyControls.defaultProps = {
  hideSimilar: false,
  isPersonalisableProduct: false,
  productUrl: undefined,
  isDisabled: false,
  depositCharge: undefined,
  displayPriceQualifier: undefined,
  conflict: null,
  productPosition: undefined,
  searchType: null,
  orderId: undefined,
};

export default memo(TrolleyControls);
